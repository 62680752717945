/**
 * Context for the Fetch Service
 *
 * Using a Context allows you to inject the apiRequest function into any component without needing to pass it down as a prop.
 */
import React, { createContext, useContext } from 'react'
import apiRequest from '../services/fetchService'

// Define the context type with generic typing for flexibility
type FetchContextType = {
  apiRequest: <T, U>(
    path: string,
    method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
    body?: U,
    extraHeaders?: Record<string, string | undefined>
  ) => Promise<T>
}

const FetchContext = createContext<FetchContextType | undefined>(undefined)

export const FetchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <FetchContext.Provider value={{ apiRequest }}>{children}</FetchContext.Provider>
)

export const useFetch = () => {
  const context = useContext(FetchContext)
  if (!context) {
    throw new Error('useFetch must be used within a FetchProvider')
  }
  return context.apiRequest
}
