import { useParams} from "react-router-dom";
import {products} from "./MachineList";
import React, {useState} from "react";
import {goToRentalPage} from "./MachinesRoot";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import './calendar.css';

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
// import Swiper core and required modules
import {Navigation, Thumbs} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const locations = [
    {
        "name": "Eliet Europe nv",
        "distance": 5.4,
        "address": "Diesveldstraat 2, 8553 Otegem",
        "phone": "+32 56 77 70 88",
        "email": "info@eliet.eu",
        "website": "https://www.elietmachines.com"
    },
    {
        "name": "ToolPro Supplies",
        "distance": 3.2,
        "address": "Industry Lane 15, 8740 Waregem",
        "phone": "+32 56 80 12 34",
        "email": "contact@toolpro.be",
        "website": "https://www.toolpro.be"
    },
    {
        "name": "Garden Solutions",
        "distance": 8.7,
        "address": "Greenwood Ave 45, 8700 Tielt",
        "phone": "+32 51 67 89 12",
        "email": "support@gardensolutions.be",
        "website": "https://www.gardensolutions.be"
    },
    {
        "name": "TechHub Kortrijk",
        "distance": 12.3,
        "address": "Innovation Street 7, 8500 Kortrijk",
        "phone": "+32 56 99 44 33",
        "email": "info@techhubkortrijk.be",
        "website": "https://www.techhubkortrijk.be"
    },
    {
        "name": "EcoFarms Belgium",
        "distance": 6.1,
        "address": "Farming Road 23, 8791 Waregem",
        "phone": "+32 56 70 55 90",
        "email": "info@ecofarms.be",
        "website": "https://www.ecofarms.be"
    },
    {
        "name": "City Electronics",
        "distance": 4.5,
        "address": "Main Square 101, 8501 Heule",
        "phone": "+32 56 88 22 44",
        "email": "sales@cityelectronics.be",
        "website": "https://www.cityelectronics.be"
    }
]

// Calendar (https://projects.wojtekmaj.pl/react-calendar/)
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface MachineDetailProps {
    // define props if any
}

const MachineDetail: React.FC<MachineDetailProps> = () => {
    const {id} = useParams<{ id: string }>(); // Specify that id is a string

    const selectedMachine = id ? products.find(product => product.id === parseInt(id)) : null;

    if (!selectedMachine) {
        return <p>No machine found with ID: {id}</p>;
    }

    const {title, price, description, image} = selectedMachine;

    // Calendar
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    const [value, onChange] = useState<Value>(tomorrow);

    const tileDisabled = ({date, view}) => {
        // Disable Sundays
        return view === 'month' && date.getDay() === 0; // 0 is Sunday
    };

    // store thumbs swiper instance
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
            <div className="my-10 grid-default" data-id={id}>
                <div className="col-[1/7]">
                        <div>
                            <Swiper
                                modules={[Navigation, Thumbs]}
                                navigation
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log('Swiper init', swiper)}
                            >{/*thumbs={{ swiper: thumbsSwiper || {} }}*/}

                                {Array.from({length: 6}, (_, index) => (
                                    <SwiperSlide key={`slide${index}`}>
                                        {index % 2 === 1 ? (
                                            <video className="w-full aspect-square object-cover bg-primary" playsInline autoPlay loop>
                                                <source src="https://elietpaas.ddev.site/uploads/videos/1080625157-preview.mp4" type="video/mp4"/>
                                            </video>
                                        ) : (
                                            <img className="w-full aspect-square object-cover" src={`https://placecats.com/1024/1024`} alt={`nature-${index + 1}`}/>
                                        )}
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <div className="mt-3">
                            <Swiper
                                modules={[Navigation, Thumbs]}
                                navigation
                                spaceBetween={12}
                                slidesPerView={4}
                                watchSlidesProgress
                                onSwiper={setThumbsSwiper}
                            >
                                <SwiperSlide key={10000}>
                                    <video className="w-full aspect-square object-cover bg-primary" playsInline autoPlay loop>
                                        <source src="https://elietpaas.ddev.site/uploads/videos/1080625157-preview.mp4" type="video/mp4"/>
                                    </video>
                                </SwiperSlide>
                                {Array.from({length: 6}, (_, index) => (
                                    <SwiperSlide key={`thumb${index}`}>
                                        {index % 2 === 1 ? (
                                            <video className="w-full aspect-square object-cover bg-primary" playsInline autoPlay loop>
                                                <source src="https://elietpaas.ddev.site/uploads/videos/1080625157-preview.mp4" type="video/mp4"/>
                                            </video>
                                        ) : (
                                            <img className="w-full aspect-square object-cover" src={`https://placecats.com/162/162`} alt={`nature-${index + 1}`}/>
                                        )}
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                </div>
                <div className="col-[8/13]">
                    <div className="grid gap-10">
                        <div className="xmb-10 flex justify-between items-end">
                            <h2 className="m-0 text-5xl">{title}</h2>
                            <span className="m-0 text-4xl text-primary italic">{price}/day</span>
                        </div>
                        <div dangerouslySetInnerHTML={{
                            __html: description
                        }}/>
                        <div className="flex gap-8">
                            <button className="btn" onClick={() => goToRentalPage(id)}>Rental</button>
                        </div>
                        <div>
                            <p className="text-xl">Technical:</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-10">
                <p className="text-4xl">Rent by location</p>
                <div className="grid-default gaps-big grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                    {locations.map(location => (
                        <div key={location.name} className="bg-light">
                            <div className="flex justify-between items-center gap-1">
                                <p className="text-lg text-primary">{location.name}</p>
                                <span>{location.distance}km</span>
                            </div>
                            <p>
                                {location.address}<br/>
                                Tel: <a href="tel:{location.phone}">{location.phone}</a><br/>
                                E-mail: <a href="mailto:{location.email}">{location.email}</a><br/>
                                <a href="https://www.elietmachines.com" target="_blank">{location.website}</a></p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="my-10">
                <p className="text-4xl">Rent by date: [{formatDateTime(value)}]</p>
                <Calendar onChange={onChange} value={value} minDate={tomorrow} tileDisabled={tileDisabled}/>
            </div>
        </>
    )
}

export default MachineDetail

function formatDateTime(dateTime: number): string {
    return new Date(dateTime).toLocaleString('nl-BE', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
        second: '2-digit',
    })
}