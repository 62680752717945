import SimpleLightbox from 'simple-lightbox';

import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, EffectFade, Thumbs } from "swiper/modules";
import { SwiperOptions } from "swiper/types";

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export const simpleLightbox = () => {
    //console.log('Simple Lightbox initialized');
    new SimpleLightbox({
        elements: document.querySelectorAll('.lightBoxGallery a'),
        //nextBtnClass: ' icon-arrow-right',
        //prevBtnClass: ' icon-arrow-right',
    });
};

/**
 * Swiper functions
 */
export const swiperDefault = (selector: string = '.swiperDefault') => {
    const swiperElements = document.querySelectorAll<HTMLElement>(selector);

    if (!swiperElements.length) return;

    swiperElements.forEach((swiperEl) => {
        const section = swiperEl.closest<HTMLElement>('.section');

        if (!section) return;

        const pagination = section.querySelector<HTMLElement>('.swiper-pagination');

        const swiperParams: SwiperOptions = {
            modules: [Navigation, Pagination, Autoplay, EffectFade],
            effect: 'fade',
            watchOverflow: true,
            grabCursor: true,
            autoHeight: swiperEl.dataset.autoHeight === 'true',
            pagination: pagination
                ? {
                    el: pagination,
                    type: 'bullets',
                    clickable: true,
                }
                : undefined,
            navigation: {
                nextEl: section.querySelector<HTMLElement>('.swiper-button-next') ?? undefined,
                prevEl: section.querySelector<HTMLElement>('.swiper-button-prev') ?? undefined,
            },
            autoplay: swiperEl.dataset.autoplayDelay
                ? {
                    pauseOnMouseEnter: true,
                    delay: parseInt(swiperEl.dataset.autoplayDelay) || 3000, // Ensure valid number or default
                }
                : undefined,
            speed: parseInt(swiperEl.dataset.speed) || 640,
            slidesPerView: parseInt(swiperEl.dataset.slidesPerView) || 1,
            spaceBetween: parseInt(swiperEl.dataset.spaceBetween) || 0,
            on: {
                init: () => {
                    // Optional: add initialization logic
                },
            },
        };

        new Swiper(swiperEl, swiperParams); // Initialize Swiper

        /*sw.on('slideChange', function () {
          //console.log('slide changed');
        });*/
    });
};

export const swiperGallery = (selector: string = '.swiperGallery') => {
    const swiperElements = document.querySelectorAll<HTMLElement>(selector);

    if (!swiperElements.length) return;

    swiperElements.forEach((swiperEl) => {
        const section = swiperEl.closest<HTMLElement>('.section');

        if (!section) return;

        let swiperGalleryThumbs = null

        if (section.querySelector<HTMLElement>('.swiperGalleryThumbs')) {
            const swiperThumbs = section.querySelector<HTMLElement>('.swiperGalleryThumbs');

            swiperGalleryThumbs = new Swiper(swiperThumbs, {
                modules: [Navigation],
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                spaceBetween: parseInt(swiperThumbs.dataset.spaceBetween) || 0,
                slidesPerView: parseInt(swiperThumbs.dataset.slidesPerView) || 3,
                //freeMode: true,
                watchSlidesProgress: true,
            });
        }

        new Swiper(swiperEl, {
            modules: [Navigation, EffectFade, Thumbs],
            effect: 'fade',
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            thumbs: {
                swiper: swiperGalleryThumbs,
            },
        })
    });
}

/**
 * GSAP functions
 */
export const gsapBatchUpBase = (selector: string = '.gsapBatchUpBase') => {
    ScrollTrigger.saveStyles(selector);

    //ScrollTrigger.matchMedia({
    // desktop
    //'(min-width: 768px)': function () {
    gsap.utils.toArray<HTMLElement>('.section').forEach((block: HTMLElement) => {
        const items = block.querySelectorAll<HTMLElement>(selector);

        if (!items.length) return;

        gsap.set(items, {
            autoAlpha: 0,
                duration: 1000,
            y: 20,
        });

        const stItems = ScrollTrigger.batch(items, {
            interval: 0.3,
            start: 'top bottom',
            end: 'bottom top',
            onEnter: (batch) =>
                gsap.to(batch, {
                    autoAlpha: 1,
                    y: 0,
                    stagger: 0.2,
                    ease: '0.79, 0.14, 0.15, 0.86',
                }),
        });

        const resizeObserver = new ResizeObserver(() => {
            stItems.forEach(stItem => stItem.refresh());
        });

        resizeObserver.observe(block);
    });
    // mobile
    // '(max-width: 767px)': function () {},
    // // all
    // all: function () {
    //   // ScrollTriggers created here aren't associated with a particular media query,
    //   // so they persist.
    // }
    //});
};