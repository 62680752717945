import React, {useMemo} from "react";
import {Link, useLocation} from "react-router-dom";
import {BreadcrumbArrowIcon} from "./BreadcrumbArrowIcon";

const BreadcrumbItem: React.FC<{
    path: string;
    isActive: boolean;
    isLink: boolean;
    label: string;
}> = ({path, isActive, isLink, label}) => (
    <li className="flex items-center h-full">
        {isLink ? (
            <Link to={path} className={`py-1 ${isActive ? 'active-breadcrumb' : ''}`}>
                {label}
            </Link>
        ) : (
            <span className={`py-1 ${isActive ? 'active-breadcrumb' : ''}`}>
                {label}
            </span>
        )}
    </li>
);

const Breadcrumbs: React.FC<{ stepsList: BreadcrumbStep[] }> = ({ stepsList = [] }) => {

    const steps: BreadcrumbStep[] = useMemo(() => stepsList ?? [], [stepsList]);

    const routerLocation = useLocation();
    const currentPath = routerLocation.pathname;
    const currentStep = parseInt(currentPath.replace('/', '')) || 1;

    return (
        <nav className="p-3 pt-1.5 pb-0 border border-neutral-200/60 rounded" aria-label="breadcrumb">
            <ol className="breadcrumbs | inline-flex items-center space-x-1 text-sm [&_.active-breadcrumb]:text-primary leading-none">
                {/*<li className="flex items-center h-full">
                    <Link to={ROUTES.LIST} className="py-1">
                        <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z"/>
                            <path
                                d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z"/>
                        </svg>
                    </Link>
                </li>
                <BreadcrumbArrowIcon/>*/}
                {steps.map((step, idx) => (
                    <React.Fragment key={step.path}>
                        <BreadcrumbItem
                            path={step.path}
                            isActive={currentPath === step.path}
                            isLink={(idx + 1) < currentStep}
                            label={step.label}
                        />
                        {idx < steps.length - 1 && <BreadcrumbArrowIcon/>}
                    </React.Fragment>
                ))}
            </ol>
        </nav>
    )
}

export default Breadcrumbs