import {MemoryRouter, Routes, Route, Outlet} from 'react-router-dom';
import React, { Suspense } from 'react';
import Breadcrumbs from "./Breadcrumbs";
import MachineList from "./MachineList";
import MachineDetail from "./MachineDetail";
import Footer from "../layout/Footer";
const ErrorPage = React.lazy(() => import('../../pages/PageNotFound'));

export const ROUTES = {
    LIST: '/',
    DETAIL: '/detail/:id',
}

export const lang = document.documentElement.lang;

export const goToRentalPage = (id) => {
    window.location.href = `/${lang}/rental-locations?machine=${id}`;
};

const MachinesLayout: React.FC = () => {
    return (
        <div id="wrapperReact">
            <header>
                <Breadcrumbs />
            </header>
            <main className="my-10">
                <Outlet />
            </main>
            <Footer />
        </div>
    );
}

const MachinesRoot: React.FC = () => {
    return (
        <MemoryRouter>
            <Routes>
                <Route path={ROUTES.LIST} element={<MachinesLayout />}>
                    <Route index element={<MachineList />} /> {/* Default route at /machines */}
                    <Route path={ROUTES.DETAIL} element={<MachineDetail />} />
                    <Route path="*" element={
                        <Suspense fallback={<div>Loading Error...</div>}>
                            <ErrorPage />
                        </Suspense>
                    } />
                </Route>
            </Routes>
        </MemoryRouter>
    );
};

export default MachinesRoot;
