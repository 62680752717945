import {useAuth} from '../../hooks/useAuth'
import Alert from "../layout/Alert";

const FormRegister = () => {
    const {register, loading, error} = useAuth()

    const handleSubmit = (e) => {
        e.preventDefault()

        const fd = new FormData(e.target)
        const formData = Object.fromEntries(fd.entries())
        console.log(formData)

        register(formData)
    }

    return (
        <form onSubmit={handleSubmit} className="space-y-5">
            {error && (
                <Alert title="Er was een probleem bij het registreren:" message={error}/>
            )}
            <div>
                <label htmlFor="descr">
                    Name&nbsp;<span className="text-danger-500" aria-hidden="true">*</span>
                </label>
                <input type="text" id="descr" name="descr" required />
            </div>
            <div>
                <label htmlFor="userName">
                    E-mailadres&nbsp;<span className="text-danger-500" aria-hidden="true">*</span>
                </label>
                <input type="email" id="userName" name="userName" required />
            </div>
            <div>
                <label htmlFor="userPassword">
                    Paswoord&nbsp;<span className="text-danger-500" aria-hidden="true">*</span>
                </label>
                <input type="password" id="userPassword" name="userPassword" required />
            </div>
            <button type="submit" className="btn btn-sm" disabled={loading}>
                {loading ? 'Aan het registreren...' : 'Registreren'}
            </button>
        </form>
    );
};

export default FormRegister;
