import {Link} from "react-router-dom";
import React from "react";

const RentalStep3 = () => {
    return (
        <div>
            <h2 className="text-4xl">Rental Step 3</h2>
            <div className="btnContainer">
                <Link to={`/2`} className="btn btn-outline">
                    Back to step 2
                </Link>
                <button className="btn btn-primary">
                    Finish
                </button>
            </div>
        </div>
    )
}

export default RentalStep3