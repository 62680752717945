import React from "react";
import ReactDOM from 'react-dom/client';
import {FetchProvider} from "./context/FetchContext";
import MachinesRoot from './components/machines/MachinesRoot';
import RentalRoot from "./components/rental/RentalRoot";
import OrderRoot from "./components/order/OrderRoot";
import AccountRoot from "./components/account/AccountRoot";
import './translations/i18n'

const mountComponent = (selector: string, Component: React.FC) => {
    const element = document.querySelector(selector);

    if (element) {
        ReactDOM.createRoot(element).render(
            <React.StrictMode>
                <FetchProvider>
                    <Component/>
                </FetchProvider>
            </React.StrictMode>
        );
    } else {
        //console.warn(`No element found with selector: ${selector}`);
    }
};

export const mountReactApp = () => {
    mountComponent('#reactRoot', MachinesRoot);
    mountComponent('#reactRental', RentalRoot);
    mountComponent('#reactOrder', OrderRoot);
    mountComponent('#reactAccount', AccountRoot);
};
