import {MemoryRouter, Outlet, Routes, Route} from 'react-router-dom';
import React, {Suspense} from 'react';
import Breadcrumbs from "./Breadcrumbs";
import RentalStep1 from "./RentalStep1";
import RentalStep2 from "./RentalStep2";
import RentalStep3 from "./RentalStep3";

const ErrorPage = React.lazy(() => import('../../pages/PageNotFound'));

export const ROUTES = {
    STEP_1: '/',
    STEP_2: '/2',
    STEP_3: '/3',
    ERROR: '*',
};

export const lang = document.documentElement.lang;

const SuspenseErrorPage: React.FC = () => (
    <Suspense fallback={<div>Loading Error...</div>}>
        <ErrorPage />
    </Suspense>
);

const RentalLayout: React.FC = () => {
    return (
        <div id="wrapperReact">
            <header>
                <Breadcrumbs/>
            </header>
            <main className="my-10">
                {/*{machineId ? (
                    <p>Selected Machine ID: {machineId}</p>
                ) : (
                    <p>No machine selected.</p>
                )}*/}
                <Outlet/>
            </main>
        </div>
    );
}

const RentalRoot: React.FC = () => {
    console.log('RentalRoot rendered');
    return (
        <MemoryRouter>
            <Routes>
                <Route path={ROUTES.STEP_1} element={<RentalLayout/>}>
                    <Route index element={<RentalStep1/>}/>
                    <Route path={ROUTES.STEP_2} element={<RentalStep2/>}/>
                    <Route path={ROUTES.STEP_2} element={<RentalStep3/>}/>
                    <Route path={ROUTES.ERROR} element={<SuspenseErrorPage/>}/>
                </Route>
            </Routes>
        </MemoryRouter>
    );
};

export default RentalRoot;
