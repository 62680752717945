import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import nlJSON from './nl/nl.json'
import enJSON from './en/en.json'

i18n.use(initReactI18next).init({
  resources: {
    nl: { ...nlJSON },
    en: { ...enJSON },
  },
  lng: 'nl',
})

export default i18n