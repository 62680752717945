import {Link} from "react-router-dom";
import React from "react";
import Alert from "../layout/Alert";

const RentalStep2 = () => {
    return (
        <div>
            <h2 className="text-4xl">Rental Step 1</h2>

            <div className="my-10">
                <Alert type="warning" />
            </div>

            <div className="my-10">
                    <div className="flex flex-col">
                        <div className="overflow-x-auto">
                            <div className="inline-block min-w-full">
                                <div className="overflow-hidden border border-light rounded">
                                    <table className="min-w-full divide-y divide-light">
                                        <thead className="bg-neutral-50">
                                        <tr className="text-neutral-500">
                                            <th className="px-5 py-3 text-xs font-medium text-left uppercase">Name</th>
                                            <th className="px-5 py-3 text-xs font-medium text-left uppercase">Age</th>
                                            <th className="px-5 py-3 text-xs font-medium text-left uppercase">Address</th>
                                            <th className="px-5 py-3 text-xs font-medium text-right uppercase">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-light">
                                        <tr className="text-neutral-800">
                                            <td className="px-5 py-4 text-sm font-medium whitespace-nowrap">Richard Hendricks</td>
                                            <td className="px-5 py-4 text-sm whitespace-nowrap">30</td>
                                            <td className="px-5 py-4 text-sm whitespace-nowrap">Pied Piper HQ, Palo Alto</td>
                                            <td className="px-5 py-4 text-sm font-medium text-right whitespace-nowrap">
                                                <a className="text-blue-600 hover:text-blue-700" href="#">Edit</a>
                                            </td>
                                        </tr>
                                        <tr className="text-neutral-800">
                                            <td className="px-5 py-4 text-sm font-medium whitespace-nowrap">Erlich Bachman</td>
                                            <td className="px-5 py-4 text-sm whitespace-nowrap">40</td>
                                            <td className="px-5 py-4 text-sm whitespace-nowrap">5230 Penfield Ave, Woodland Hills / 5230 Penfield Ave, Woodland Hills</td>
                                            <td className="px-5 py-4 text-sm font-medium text-right whitespace-nowrap">
                                                <a className="text-blue-600 hover:text-blue-700" href="#">Edit</a>
                                            </td>
                                        </tr>
                                        <tr className="text-neutral-800">
                                            <td className="px-5 py-4 text-sm font-medium whitespace-nowrap">Monica Hall</td>
                                            <td className="px-5 py-4 text-sm whitespace-nowrap">35</td>
                                            <td className="px-5 py-4 text-sm whitespace-nowrap">2030 Stewart Drive, Sunnyvale</td>
                                            <td className="px-5 py-4 text-sm font-medium text-right whitespace-nowrap">
                                                <a className="text-blue-600 hover:text-blue-700" href="#">Edit</a>
                                            </td>
                                        </tr>
                                        <tr className="text-neutral-800">
                                            <td className="px-5 py-4 text-sm font-medium whitespace-nowrap">Dinesh Chugtai</td>
                                            <td className="px-5 py-4 text-sm whitespace-nowrap">28</td>
                                            <td className="px-5 py-4 text-sm whitespace-nowrap">Pied Piper HQ, Palo Alto</td>
                                            <td className="px-5 py-4 text-sm font-medium text-right whitespace-nowrap">
                                                <a className="text-blue-600 hover:text-blue-700" href="#">Edit</a>
                                            </td>
                                        </tr>
                                        <tr className="text-neutral-800">
                                            <td className="px-5 py-4 text-sm font-medium whitespace-nowrap">Gilfoyle</td>
                                            <td class="px-5 py-4 text-sm whitespace-nowrap">32</td>
                                            <td class="px-5 py-4 text-sm whitespace-nowrap">Pied Piper HQ, Palo Alto</td>
                                            <td class="px-5 py-4 text-sm font-medium text-right whitespace-nowrap">
                                                <a class="text-blue-600 hover:text-blue-700" href="#">Edit</a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

            <div className="btnContainer">
                <Link to={`/`} className="btn btn-outline">
                    Back to step 1
                </Link>
                <Link to={`/3`} className="btn btn-outline">
                    To step 3
                </Link>
            </div>
        </div>
    )
}

export default RentalStep2