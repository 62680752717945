import {Link} from "react-router-dom";

const ErrorPage = () => {
    return (
        <div className="container section grid min-h-full place-items-center">
            <div className="text-center">
                <p className="m-0 font-medium">404</p>
                <p className="mt-1 text-4xl">
                    Page not found
                </p>
                <p className="mt-6 text-base leading-7 text-gray-600">
                    Sorry, we couldn’t find the page you’re looking for.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link to="/" className="btn btn-sm">
                        Go back home
                    </Link>
                    <a href="mailto:nick@consigo.be?subject=Eliet" className="btn btn-sm btn-outline">
                        Contact support <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;
