import {Link, useLocation} from "react-router-dom";
import {ROUTES} from "../machines/MachinesRoot";
import React from "react";

const ArrowIcon: React.FC = () => (
    <svg className="w-5 h-5 text-neutral-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g fill="none" stroke="none">
            <path d="M10 8.013l4 4-4 4" stroke="currentColor" strokeWidth="1" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </g>
    </svg>
)

const steps = [
    { path: '/', label: 'Stap 1' },
    { path: '/2', label: 'Stap 2' },
    { path: '/3', label: 'Stap 3' },
];

const Breadcrumbs: React.FC = () => {
    const routerLocation = useLocation();
    const currentPath = routerLocation.pathname;
    const isRootPath = currentPath === '/';

    return (
        <nav className="p-3 pt-1.5 pb-0 border border-neutral-200/60 rounded" aria-label="breadcrumb">
            <ol className="breadcrumbs | inline-flex items-center space-x-1 text-sm [&_.active-breadcrumb]:text-primary leading-none">
                <li className="flex items-center h-full">
                    <Link to={ROUTES.LIST} className="py-1">
                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                             fill="currentColor">
                            <path
                                d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z"/>
                            <path
                                d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z"/>
                        </svg>
                    </Link>
                </li>
                <ArrowIcon/>
                {steps.map((step, idx) => (
                    <React.Fragment key={step.label}>
                        <li className="flex items-center h-full">
                            <Link to={step.path} className={`py-1 ${currentPath === step.path ? 'active-breadcrumb' : ''}`}>
                                {step.label}
                            </Link>
                        </li>
                        {idx < steps.length - 1 && <ArrowIcon />}
                    </React.Fragment>
                ))}
            </ol>
        </nav>
    )
}

export default Breadcrumbs