import MachineItem from "./MachineItem";

interface MachineListProps {
    // define props if any
}

export const products = [
    {
        id: 1,
        image: {
            src: "https://elietpaas.ddev.site/uploads/images/sfeer/DSC7511.jpg",
            alt: "foto"
        },
        title: "Jetzer",
        price: 100,
        short: '<p>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.</p>',
        description: '<p>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.</p><p>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.</p>'
    },
    {
        id: 2,
        title: "Product x",
        price: 99.99,
        short: "<p>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.</p>",
        description: "<p>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.</p><p>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.</p>"
    },
    {
        id: 3,
        image: {
            src: "https://elietpaas.ddev.site/uploads/images/sfeer/DSC7511.jpg",
            alt: "foto"
        },
        title: "Product y",
        price: 99.99,
        short: "<p>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.</p>",
        description: "<p>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.</p><p>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.</p>"
    }
];

const MachineList: React.FC<MachineListProps> = () => {
    return (
        <div className="grid grid-cols-3 gap-5">
            {products.map((machine, index) => (
                <MachineItem key={index} url={`/detail/${machine.id}`} {...machine} />
            ))}
        </div>
    )
}

export default MachineList