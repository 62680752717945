import React from "react"
import {MemoryRouter, Outlet, Routes, Route} from 'react-router-dom'
import AuthRoute from "../auth/AuthRoute"
import Account from "./Account";
import AccountLogin from "./AccountLogin";
import ErrorPage from "../../pages/PageNotFound";
import TestComponent from "../TestComponent"

export const lang = document.documentElement.lang

export const ROUTES = {
    ROOT: '/',
    LOGIN: '/login',
    TEST: '/test',
    ERROR: '*',
}

const AccountLayout: React.FC = () => {
    return (
        <div id="wrapperReact">
            <Outlet/>
        </div>
    );
}

const AccountRoot: React.FC = () => {
    return (
        <MemoryRouter>
            <Routes>
                <Route path={ROUTES.ROOT} element={<AccountLayout/>}>
                    <Route element={<AuthRoute routeLogin={ROUTES.LOGIN}/>}>
                        <Route index element={<Account/>}/>
                        <Route path={ROUTES.TEST} element={<TestComponent/>}/>
                    </Route>
                    <Route path={ROUTES.LOGIN} element={<AccountLogin/>}/>
                    <Route path={ROUTES.ERROR} element={<ErrorPage/>}/>
                </Route>
            </Routes>
        </MemoryRouter>
    );
}

export default AccountRoot