import React, {Suspense} from 'react';
import {MemoryRouter, Routes, Route, Outlet} from 'react-router-dom';
import Breadcrumbs from "../layout/Breadcrumbs";
import OrderStep1 from "./OrderStep1";
import OrderStep2 from "./OrderStep2";
import OrderStep3 from "./OrderStep3";
import OrderStep4 from "./OrderStep4";
import OrderStep5 from "./OrderStep5";
import OrderStep6 from "./OrderStep6";
import {useTranslation} from "react-i18next";

const ErrorPage = React.lazy(() => import('../../pages/PageNotFound'));

export const ROUTES = {
    STEP_1: '/',
    STEP_2: '/2',
    STEP_3: '/3',
    STEP_4: '/4',
    STEP_5: '/5',
    STEP_6: '/6',
    ERROR: '*',
}

export const lang = document.documentElement.lang;

const OrderLayout: React.FC<{ breadcrumbs: BreadcrumbStep[] }> = ({breadcrumbs = []}) => {
    return (
        <div id="wrapperReact">
            <header>
                <Breadcrumbs stepsList={breadcrumbs}/>
            </header>
            <main className="my-10">
                <Outlet/>
            </main>
        </div>
    );
}

const OrderRoot: React.FC = () => {
    const {t} = useTranslation();

    const breadcrumbs: BreadcrumbStep[] = [
        {path: ROUTES.STEP_1, label: t('currentOrder.breadcrumbs.step1')},
        {path: ROUTES.STEP_2, label: t('currentOrder.breadcrumbs.step2')},
        {path: ROUTES.STEP_3, label: t('currentOrder.breadcrumbs.step3')},
        {path: ROUTES.STEP_4, label: t('currentOrder.breadcrumbs.step4')},
        {path: ROUTES.STEP_5, label: t('currentOrder.breadcrumbs.step5')},
        {path: ROUTES.STEP_6, label: t('currentOrder.breadcrumbs.step6')},
    ]

    return (
        <MemoryRouter>
            <Routes>
                <Route path={ROUTES.STEP_1} element={<OrderLayout breadcrumbs={breadcrumbs}/>}>
                    <Route index element={<OrderStep1 stepTitle={breadcrumbs[0].label}/>}/>
                    <Route path={ROUTES.STEP_2} element={<OrderStep2 stepTitle={breadcrumbs[1].label}/>}/>
                    <Route path={ROUTES.STEP_3} element={<OrderStep3 stepTitle={breadcrumbs[2].label}/>}/>
                    <Route path={ROUTES.STEP_4} element={<OrderStep4 stepTitle={breadcrumbs[3].label}/>}/>
                    <Route path={ROUTES.STEP_5} element={<OrderStep5 stepTitle={breadcrumbs[4].label}/>}/>
                    <Route path={ROUTES.STEP_6} element={<OrderStep6 stepTitle={breadcrumbs[5].label}/>}/>
                    <Route path="*" element={
                        <Suspense fallback={<div>Loading Error...</div>}>
                            <ErrorPage/>
                        </Suspense>
                    }/>
                </Route>
            </Routes>
        </MemoryRouter>
    );
};

export default OrderRoot;
