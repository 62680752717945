
export function isTokenExpired(token) {
    const tokenArray = token.split('.')
    const tokenValue = JSON.parse(atob(tokenArray[1]))
    //console.log('isTokenExpired:', Math.floor(new Date().getTime() / 1000) >= tokenValue?.exp)
    return Math.floor(new Date().getTime() / 1000) >= tokenValue?.exp
}

export function getAuthToken() {
    const token = localStorage.getItem('userToken')

    if (!token || token === 'null') {
        console.warn('No token found in localStorage')
        return null
    }

    if (isTokenExpired(token)) {
        console.warn('Token is expired')
        localStorage.removeItem('userToken')
        localStorage.removeItem('user')
        return null //return redirect('/login')
    }

    return JSON.parse(token)
}