import {Navigate, Outlet} from 'react-router-dom'
import {useAuth} from '../../hooks/useAuth'
import {getAuthToken} from "../../utils/auth";
import {User} from "../../models/global.model";

const AuthRoute: React.FC<{ routeLogin: string }> = ({routeLogin}) => {
    const {user} = useAuth<User>()
    const token = getAuthToken()
    //console.log('AuthRoute', user, token)

    if (!user || !token) {
        return <Navigate to={routeLogin} replace/>
    }

    return <Outlet/>
}

export default AuthRoute