import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const OrderStep6: React.FC<{ stepTitle?: string }> = ({ stepTitle = '?' }) => {
    const {t} = useTranslation();

    const nextStepPath = "/2";
    return (
        <div>
            <h2 className="text-4xl">{stepTitle}</h2>
            <div className="btnContainer">
                <Link to={`/5`} className="btn btn-outline">
                    Back to 5
                </Link>
                <button className="btn btn-primary">
                    Finish
                </button>
            </div>
        </div>
    )
}

export default OrderStep6