import '@/css/app.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import 'simple-lightbox/dist/simpleLightbox.min.css';

import { initializeKinesis } from "@amineyarman/kinesis";
import { init as CookieInit } from 'cookie-though';
import { simpleLightbox, gsapBatchUpBase, swiperDefault, swiperGallery } from './components/defaults'

import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
Alpine.plugin([focus, collapse])

//import { mountVueApp } from '@/vue/app.ts';
import { mountReactApp } from '../react/app'; //'@/react/app.tsx';

interface CurrentOrder {
    message: string | null;
    machineType: string; // adjust this type if machineType is more complex
    selectedDates: string[]; // adjust this type if needed
    location: string | null; // adjust this type as needed
}

const main = async () => {
    document.addEventListener("DOMContentLoaded", () => {
        initializeKinesis();
    });

    cookieThough()
    setTimeout(() => gsapBatchUpBase(), 300)
    swiperDefault()
    swiperGallery()
    simpleLightbox()

    // Vue / React
    //mountVueApp();
    mountReactApp()

    newsletter()

    document.addEventListener('alpine:init', () => {
        // Create a URL object to parse the query string of the url
        const params = new URLSearchParams(new URL(window.location.href).search);
        const partner = params.get('partner') ?? null;
        const location = params.get('location') ?? null;
        console.log('partner', partner, 'location', location)

        const locationsAlpine = document.getElementById('locationsAlpine')
        const machineType = locationsAlpine ? locationsAlpine!.dataset?.machinetype : null

        Alpine.store('currentOrder', {
            message: null,
            machineType: machineType,
            selectedDates: [],
            partner: partner,
            location: location,
            products: [],
        } as CurrentOrder)

        // Load existing data from localStorage (if any)
        const savedOrder: CurrentOrder = JSON.parse(localStorage.getItem('currentOrder') || '{}')
        if (savedOrder) {
            Alpine.store('currentOrder').message = savedOrder.message
            Alpine.store('currentOrder').machineType = savedOrder.machineType ?? machineType
            Alpine.store('currentOrder').selectedDates = savedOrder.selectedDates
            Alpine.store('currentOrder').partner = savedOrder.partner ?? partner
            Alpine.store('currentOrder').location = savedOrder.location ?? location
            Alpine.store('currentOrder').products = savedOrder.products ?? []
        }

        // Watch for changes and save to localStorage
        Alpine.effect(() => {
            const currentOrder = Alpine.store('currentOrder');

            const dataToStore = {
                message: currentOrder.message,
                machineType: currentOrder.machineType,
                selectedDates: currentOrder.selectedDates,
                partner: currentOrder.partner,
                location: currentOrder.location,
                products: currentOrder.products,
            };
            localStorage.setItem('currentOrder', JSON.stringify(dataToStore));
            console.log('Saved currentOrder to localStorage:', dataToStore);
        });
    });

    document.addEventListener('alpine:init', () => {
        Alpine.data('toastSystem', () => ({
            toastsList: [],

            init() {
                // Fetch flash messages from the DOM
                const flashMessages = JSON.parse(document.getElementById('flash-data').textContent || '{}');
                console.log('Flash messages:', flashMessages);

                // Add flash messages to the toast list
                Object.keys(flashMessages).forEach((type) => {
                    if (type === 'error' || type === 'warning' || type === 'success') {
                        const messages = Array.isArray(flashMessages[type]) ? flashMessages[type] : [flashMessages[type]];

                        messages.forEach((message) => {
                            this.addToast(type, message);
                        });
                    }
                });

                // Listen for custom toast events
                window.addEventListener('toast', (event) => {
                    const { type, message } = event.detail;
                    this.addToast(type, message);
                });
            },

            addToast(type, message) {
                // Add a toast with a unique ID for tracking
                const id = Date.now() + Math.random();
                this.toastsList.push({ id, type, message });

                // Automatically remove the toast after 5 seconds
                /*setTimeout(() => {
                    this.removeToast(id);
                }, 50000); // Auto-remove after 5 seconds*/
            },

            removeToast(id) {
                this.toastsList = this.toastsList.filter((toast) => toast.id !== id);
            },
        }));
    });



    const components = ['navMobile', 'customLocationsList', 'customOrderDashboard', 'customOrderProducts', 'customOrderSummary'];

    const promises = [];

    for (const comp of components) {
        const compElements = document.querySelectorAll(`.${comp}`);

        const { init } = await import(`./components/${comp}.ts`);

        compElements.forEach((comp) => {
            promises.push(init(comp));
        });
    }

    await Promise.all(promises).catch((e) => {
        console.error(e.message, e);
    });
};

const cookieThough = () => {
    setTimeout(() => {
        CookieInit(
            translations?.cookieThough
            /*{
            "policies": [
                {
                    "id": "essential",
                    "label": "Essential Cookies",
                    "description": "We need to save some technical cookies, for the website to function properly.",
                    "category": "essential",
                },
                {
                    "id": "functional",
                    "label": "Functional Cookies",
                    "category": "functional",
                    "description": "We need to save some basic preferences eg. language.",
                },
                {
                    "id": "statistics",
                    "label": "Statistics",
                    "category": "statistics",
                    "description": "We need to save some technical cookies, for the website to function properly.",
                },
                {
                    "id": "social",
                    "label": "Social Media Cookies",
                    "category": "social",
                    "description": "We need to save some social cookies, for the website to function properly.",
                },
            ],
            "essentialLabel": "Always on",
            "permissionLabels": {
                "accept": "Accept",
                "acceptAll": "Accept all",
                "decline": "Decline"
            },
            "cookiePreferenceKey": "cookie-preferences",
            "header": {
                "title": "cookie though?",
                "subTitle": "You're probably fed up with these banners...",
                "description": "Everybody wants to show his best side - and so do we. That’s why we use cookies to guarantee you a better experience."
            },
            "cookiePolicy": {
                "url":"https://inthepocket.com/cookie-policy",
                "label":"Read the full cookie declaration",
            },
            "customizeLabel": "Customize"
        }*/);
    }, 1000);

    /*document.getElementById("re-consent").addEventListener("click", () => {
      CookieThough.show();
    });*/
};

const newsletter = () => {
    const newsletterButton = document.querySelector('a[href="#newsletter"]');

    if (newsletterButton) {
        newsletterButton.addEventListener('click', triggerNewsletterOpen);
    }

    function triggerNewsletterOpen() {
        document.getElementById('newsletter-button').click();
    }
}

main().then(() => {
    console.info('Vite <3');
    Alpine.start();
});