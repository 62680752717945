import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface MachineType {
    id: string;
    type: string;
}
interface Partner {
    id: string;
    name: string;
    locations: { addressStreet: string; addressNr: string; addressPostalCode: string; addressCity: string }[];
}
interface OrderStep1Props {
    stepTitle?: string;
    machineTypes: MachineType[];
    selectedMachineTypeId: string;
    partner: Partner | null;
}
/*
interface OrderStep1Props {
    stepTitle?: string;
}
*/
const OrderStep1: React.FC<OrderStep1Props> = ({ stepTitle = '?' }) => {
    const {t} = useTranslation();

    const reactOrderElement = document.getElementById('reactOrder');
    const machineTypes = JSON.parse(reactOrderElement.getAttribute('data-machine-types') || '[]');
    console.log('machineTypes', machineTypes);
    const machineType = JSON.parse(reactOrderElement.getAttribute('data-machine-type'));
    console.log('machineType', machineType);
    const partner = JSON.parse(reactOrderElement.getAttribute('data-partner'));
    console.log('partner', partner);

    const nextStepPath = "/2";

    return (
        <div>
            {partner?.id ? (
                <>
                    <h2 className="text-4xl">Huur via locatie</h2>

                    <div className="flex flex-wrap gap-5 items-center">
                        <div className="flex flex-wrap gap-5">
                            {machineTypes.map((machineType) => (
                                <p key={machineType.id}
                                   className={`w-fit m-0 p-8 bg-light rounded | border border-light [&.active]:border-primary` + (machineType.id === machineType.id ? ' active' : '')}>
                                    <strong>machineType</strong><br/>
                                    {machineType.type}
                                </p>
                            ))}
                        </div>
                        <span>&bull;</span>
                        <p className="w-fit m-0 p-8 bg-light rounded | border border-light [&.active]:border-primary active">
                            <strong>{partner.name}</strong><br/>
                            {partner.locations[0].addressStreet} {partner.locations[0].addressNr}<br/>
                            {partner.locations[0].addressPostalCode} {partner.locations[0].addressCity}
                        </p>
                        <span>&bull;</span>
                        <div>
                            Kies datum voor machine type en partner
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <h2 className="text-4xl">Huur type machine op datum</h2>

                    <div className="flex flex-wrap gap-5 items-center">
                        <div className="flex flex-wrap gap-5">
                            {machineTypes.map((machineType) => (
                                <p key={machineType.id}
                                   className={`w-fit m-0 p-8 bg-light rounded | border border-light [&.active]:border-primary` + (machineType.id === machineType.id ? ' active' : '')}>
                                    <strong>machineType</strong><br/>
                                    {machineType.type}
                                </p>
                            ))}
                        </div>
                        <span>&bull;</span>
                        <p className="w-fit m-0 p-8 bg-light rounded | border border-light [&.active]:border-primary active">
                            <strong>Datum?</strong><br/>?
                        </p>
                        <span>&bull;</span>
                        <div>
                            Kies locatie voor machine type op datum
                        </div>
                    </div>
                </>
            )}{/*stepTitle}{/* / {t('language', {test: stepTitle})}*/}
            <div className="my-10">
                <form>
                    <fieldset className="grid gap-5">
                        <div>
                            <label htmlFor="naam" id="naam-label">
                                Naam&nbsp;<span className="text-danger-500" aria-hidden="true">*</span>
                            </label>
                            <input type="text" id="naam" name="fields[naam]" placeholder="Volledige naam" aria-labelledby="naam-label" required/>
                        </div>
                        <div className="grid gap-5 grid-cols-2">
                            <div>
                                <label htmlFor="eMailadres">
                                    E-mailadres&nbsp;<span className="text-danger-500" aria-hidden="true">*</span>
                                </label>
                                <input type="email" id="eMailadres" name="fields[eMailadres]" placeholder="E-mailadres"
                                       autoComplete="email" required/>
                            </div>
                            <div>
                                <label htmlFor="telefoon">Telefoon</label>
                                <input type="tel" id="telefoon" name="fields[telefoon]" placeholder="Telefoon"
                                       autoComplete="tel"/>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="vraag">
                                Vraag&nbsp;<span className="text-danger-500" aria-hidden="true">*</span>
                            </label>
                            <textarea id="vraag" name="fields[vraag]" rows="3" placeholder="Uw vraag of opmerking."
                                      x-data="" x-autosize="" required></textarea>
                        </div>
                        <div className="flex items-center">
                            <input type="checkbox" id="privacy" name="fields[privacy]" value="1" required/>
                            <label htmlFor="privacy">
                                Ik ga akkoord met de <a target="_blank" rel="noopener noreferrer nofollow"
                                                        href="https://elietpaas.ddev.site/nl/privacybeleid">privacyvoorwaarden</a>.
                            </label>
                        </div>
                        <div className="mt-5">
                            <button type="submit" className="btn btn-sm" data-submit-action="submit">Verzenden</button>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div className="btnContainer">
                <Link to={nextStepPath} className="btn btn-outline" title="Proceed to step 2">
                    To step 2
                </Link>
            </div>
        </div>
    )
}

export default OrderStep1