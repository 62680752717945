import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const OrderStep3: React.FC<{ stepTitle?: string }> = ({ stepTitle = '?' }) => {
    const {t} = useTranslation();

    const nextStepPath = "/4";
    return (
        <div>
            <h2 className="text-4xl">{stepTitle}</h2>
            <div className="btnContainer">
                <Link to={`/2`} className="btn btn-outline">
                    Back to step 2
                </Link>
                <Link to={nextStepPath} className="btn btn-outline">
                    To step 4
                </Link>
            </div>
        </div>
    )
}

export default OrderStep3