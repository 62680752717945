import React from 'react';

interface AlertProps {
    type?: 'danger' |'success' | 'warning'
    title?: string
    message?: string
}

const Alert: React.FC<AlertProps> = ({type = 'danger', title = 'Alert Message Headline', message = 'This is the subtext for your alert message, providing important information or instructions.'}) => {
    const cssContainer = 'relative w-full p-4 rounded border border-transparent | [&>svg]:absolute [&>svg]:text-foreground [&>svg]:left-4 [&>svg]:top-4 [&>svg+div]:translate-y-[-3px] [&:has(svg)]:pl-14'

    return (
        <>
            <div className={cssContainer + ` bg-${type}-100 text-${type}-600`}>
                {type === 'danger' && (
                    <svg className="w-7 h-7 -translate-y-0.5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.953 2C6.465 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.493 2 11.953 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.567 4 11.953 4C16.391 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"/>
                        <path d="M11 7H13V14H11V7ZM11 15H13V17H11V15Z"/>
                    </svg>
                )}
                {type === 'success' && (
                    <svg className="w-7 h-7 -translate-y-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                )}
                {type === 'warning' && (
                    <svg className="w-7 h-7 -translate-y-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                    </svg>
                )}
                <p className={`mt-0 mb-1 text-[22px] font-medium text-${type} leading-1/2 tracking-tight`}>{title}</p>
                <div className={`text-sm text-${type}-500`}>{message}</div>
            </div>
        </>
    )
}

export default Alert