import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

type OrderStep2Props = {
    stepTitle?: string;
};

const OrderStep2: React.FC<OrderStep2Props> = ({ stepTitle = '?' }) => {
    const {t} = useTranslation();

    const prevStepPath = "/";
    const nextStepPath = "/3";

    return (
        <div>
            <h2 className="text-4xl">{stepTitle}</h2>
            <div className="btnContainer">
                <Link to={prevStepPath} className="btn btn-outline">
                    Back to step 1
                </Link>
                <Link to={nextStepPath} className="btn btn-outline">
                    To step 3
                </Link>
            </div>
        </div>
    )
}

export default OrderStep2