import {useAuth} from "../../hooks/useAuth";

const Account: React.FC = () => {
    const {logout} = useAuth()

    return (
        <div>
            <h2 className="text-4xl">Account gdfgfdgd</h2>
            <button className="btn" onClick={logout}>Logout</button>
        </div>
    )
}

export default Account