import {useAuth} from '../../hooks/useAuth'
import Alert from "../layout/Alert";
import {Link} from "react-router-dom";

const FormLogin = () => {
    const {login, username, setUsername, password, setPassword, loading, error} = useAuth()

    return (
        <form onSubmit={login} className="space-y-5">
            {error && (
                <Alert title="Er was een probleem bij het inloggen:" message={error}/>
            )}
            <div>
                <label htmlFor="email">
                    E-mailadres&nbsp;<span className="text-danger-500" aria-hidden="true">*</span>
                </label>
                <input type="text" id="email" value={username} required
                       onChange={(e) => setUsername(e.target.value)}/>
            </div>
            <div>
                <label htmlFor="password">
                    Paswoord&nbsp;<span className="text-danger-500" aria-hidden="true">*</span>
                </label>
                <input type="password" id="password" value={password} required
                       onChange={(e) => setPassword(e.target.value)}/>
            </div>
            <div className="flex gap-4 items-center justify-between">
                <button type="submit" className="btn btn-sm" disabled={loading}>
                    {loading ? 'Logging in...' : 'Login'}
                </button>
                <Link to="/password" className="text-sm">Wachtwoord vergeten?</Link>
            </div>
        </form>
    );
};

export default FormLogin;
