import {Link} from "react-router-dom"
// @ts-ignore
import {
    AdvancedMarker,
    APIProvider,
    InfoWindow,
    Map,
    MapCameraChangedEvent,
    Pin,
    useMap
} from '@vis.gl/react-google-maps'
import React, {useCallback, useEffect, useRef, useState} from "react";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {products} from "../machines/MachineList";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import '../machines/calendar.css';

const GOOGLE_MAPS_API_KEY = 'AIzaSyA395SAsP9w1rbxlu09q0XMMTDP7BhQVPE';

type Poi = {
    key: string,
    location: google.maps.LatLngLiteral,
    company: string,
    street: string,
    postalCode: string,
    city: string
}
const locations: Poi[] = [
    { key: 'operaHouse', location: { lat: 50.82803, lng: 3.26586 }, company: 'Kortrijk Theatre', street: 'Theaterplein 1', postalCode: '8500', city: 'Kortrijk' },
    { key: 'tarongaZoo', location: { lat: 50.98453, lng: 3.44057 }, company: 'Deinze Animal Park', street: 'Stationsstraat 42', postalCode: '9800', city: 'Deinze' },
    { key: 'manlyBeach', location: { lat: 50.95303, lng: 3.11591 }, company: 'Izegem Lake', street: 'Kasteelstraat 15', postalCode: '8870', city: 'Izegem' },
    { key: 'hyderPark', location: { lat: 50.94593, lng: 3.11874 }, company: 'Roeselare City Park', street: 'Klein Seminariepark 3', postalCode: '8800', city: 'Roeselare' },
    { key: 'theRocks', location: { lat: 50.87682, lng: 3.33066 }, company: 'Kluisberg Nature Area', street: 'Kluisberglaan 5', postalCode: '9600', city: 'Ronse' },
    { key: 'circularQuay', location: { lat: 50.82788, lng: 3.26709 }, company: 'Kortrijk City Center', street: 'Grote Markt 1', postalCode: '8500', city: 'Kortrijk' },
    { key: 'harbourBridge', location: { lat: 50.97584, lng: 3.56695 }, company: 'Ooidonkbrug', street: 'Kasteeldreef 1', postalCode: '9800', city: 'Deinze' },
    { key: 'kingsCross', location: { lat: 50.94492, lng: 3.12171 }, company: 'Roeselare Train Station', street: 'Stationsplein 12', postalCode: '8800', city: 'Roeselare' },
    { key: 'botanicGardens', location: { lat: 50.93049, lng: 3.15518 }, company: 'Rodenbachpark', street: 'Rodenbachstraat 8', postalCode: '8800', city: 'Roeselare' },
    { key: 'museumOfSydney', location: { lat: 50.82811, lng: 3.26686 }, company: 'Kortrijk 1302 Museum', street: 'Begijnhofstraat 2', postalCode: '8500', city: 'Kortrijk' },
    { key: 'maritimeMuseum', location: { lat: 50.94665, lng: 3.11965 }, company: 'Sterrebos Museum', street: 'Sterrebosdreef 4', postalCode: '8800', city: 'Roeselare' },
    { key: 'kingStreetWharf', location: { lat: 50.94544, lng: 3.11924 }, company: 'Roeselare Port', street: 'Oostkaai 7', postalCode: '8800', city: 'Roeselare' },
    { key: 'aquarium', location: { lat: 50.82689, lng: 3.26634 }, company: 'Kortrijk Leie River Bank', street: 'Leiekaai 9', postalCode: '8500', city: 'Kortrijk' },
    { key: 'darlingHarbour', location: { lat: 50.97559, lng: 3.56585 }, company: 'Deinze Harbor', street: 'Harbourstraat 3', postalCode: '9800', city: 'Deinze' },
    { key: 'barangaroo', location: { lat: 50.93512, lng: 3.24711 }, company: 'Waregem Park', street: 'Hippodroomstraat 10', postalCode: '8790', city: 'Waregem' },
];

//https://mapstyle.withgoogle.com/
const mapStyles = [
    {
        "elementType": "geometry", // Applies to the overall geometry (physical form) of the map.
        "stylers": [{ "color": "#242f3e" }] // The base color of the map, set to a dark blue-gray tone.
    },
    {
        "elementType": "labels.text.fill", // Applies to the fill color of all text labels on the map.
        "stylers": [{ "color": "#746855" }] // Sets the text color to a muted brown tone.
    },
    {
        "elementType": "labels.text.stroke", // Applies to the outline or stroke of the text labels.
        "stylers": [{ "color": "#242f3e" }] // The stroke color of the text is the same dark blue-gray as the base map.
    },
    {
        "featureType": "administrative.locality", // Applies to labels for local administrative boundaries (e.g., cities, towns).
        "elementType": "labels.text.fill", // Styling for the text labels of administrative localities.
        "stylers": [{ "color": "#d59563" }] // Sets the text color to a light brown shade.
    },
    {
        "featureType": "poi", // Stands for "Points of Interest" like businesses, landmarks, etc.
        "elementType": "labels.text.fill", // Styling for the text labels of POIs.
        "stylers": [{ "color": "#d59563" }] // Same light brown color for POI labels.
    },
    {
        "featureType": "poi.park", // Specific to parks as points of interest.
        "elementType": "geometry", // Applies to the geometry (shape) of the park areas.
        "stylers": [{ "color": "#263c3f" }] // Dark greenish color for park areas.
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill", // Text labels for park names.
        "stylers": [{ "color": "#6b9a76" }] // Muted green color for park name labels.
    },
    {
        "featureType": "road", // General styling for all roads.
        "elementType": "geometry", // Applies to the geometry (shape) of the roads.
        "stylers": [{ "color": "#38414e" }] // Dark gray-blue color for the road surfaces.
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke", // The outline/stroke around the roads.
        "stylers": [{ "color": "#212a37" }] // Even darker gray for road strokes.
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill", // Text labels for road names.
        "stylers": [{ "color": "#9ca5b3" }] // Light gray-blue color for road labels.
    },
    {
        "featureType": "road.highway", // Specific to highways.
        "elementType": "geometry", // Styling for the surface of highways.
        "stylers": [{ "color": "#746855" }] // Muted brownish tone for highway surfaces.
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke", // Stroke or outline for highways.
        "stylers": [{ "color": "#1f2835" }] // Very dark blue-gray stroke for highways.
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill", // Text labels for highway names.
        "stylers": [{ "color": "#f3d19c" }] // Light beige color for highway labels.
    },
    {
        "featureType": "transit", // Applies to transit routes (e.g., bus, train).
        "elementType": "geometry", // Geometry (lines/shapes) of transit routes.
        "stylers": [{ "color": "#2f3948" }] // Dark blue-gray color for transit lines.
    },
    {
        "featureType": "transit.station", // Applies to transit stations.
        "elementType": "labels.text.fill", // Text labels for transit stations.
        "stylers": [{ "color": "#d59563" }] // Light brown color for transit station labels.
    },
    {
        "featureType": "water", // Applies to water bodies (e.g., rivers, lakes).
        "elementType": "geometry", // Geometry (shape) of the water bodies.
        "stylers": [{ "color": "#17263c" }] // Very dark blue color for water surfaces.
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill", // Text labels for water body names.
        "stylers": [{ "color": "#515c6d" }] // Medium gray-blue color for water labels.
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke", // Stroke/outline for water text labels.
        "stylers": [{ "color": "#17263c" }] // Same dark blue as the water body itself.
    }
];

const RentalStep1: React.FC = () => {
    const [machineId, setMachineId] = useState<string>('');

    // Runs once on component mount
    useEffect(() => {
        const params = new URLSearchParams(window.location.search); // Get URL parameters from the browser's current location
        setMachineId(params.get('machine') ?? ''); // Set a specific parameter
    }, []);

    return (
        <div className="my-10">
            <h1>Kies Machine</h1>
            <div className="grid-default grid-cols-3">
                {products.map((product, index) => (
                    <div className="" key={`p${product.id}`}>
                        <input type="radio"  className="peer hidden" required
                               id={`p${product.id}`}
                               value={product.id}
                               checked={machineId === product.id.toString()}
                               onChange={(e) => setMachineId(e.target.value)}/>
                        <label className="h-full inline-flex items-center justify-between w-full p-5 border-2 rounded cursor-pointer group border-neutral-200/70 text-neutral-600 peer-checked:border-primary-600 peer-checked:text-neutral-900 peer-checked:bg-primary-50/50 hover:text-neutral-900"
                               htmlFor={`p${product.id}`}>
                            <div className="flex items-center space-x-5">
                                <img loading="lazy" src="https://elietpaas.ddev.site/uploads/images/development/Jetzer-209.png" className="h-16 w-auto" alt="Jetzer 209"/>
                                <div className="flex flex-col justify-start">
                                    <div className="w-full text-lg font-semibold">{product.title}</div>
                                    <div className="w-full text-sm opacity-60" dangerouslySetInnerHTML={{
                                        __html: product.short
                                    }}/>
                                </div>
                            </div>
                        </label>
                    </div>
                ))}
            </div>
            {machineId && (
                <>
                    <h2>Kies een datum of locatie</h2>
                    <div className="grid-default grid-cols-3">
                        <div>
                            <Calendar locale="nl-NL" />
                        </div>
                        <div className="col-span-2">
                            <div className="rounded-large overflow-hidden">
                            <APIProvider apiKey={GOOGLE_MAPS_API_KEY} onLoad={() => console.log('Maps API has loaded.')}>
                                <Map
                                    mapId='b82104f5ef9e9e0b'
                                    style={{width: '100%', aspectRatio: 16 / 9}}
                                    defaultCenter={{lat: 50.8178, lng: 3.4014}}
                                    defaultZoom={12}
                                    gestureHandling={'greedy'}
                                    onCameraChanged={(ev: MapCameraChangedEvent) => {
                                    }
                                        //console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom)
                                    }
                                    options={{
                                        styles: mapStyles // Apply the JSON styling here
                                    }}
                                >
                                    <PoiMarkers pois={locations}/>
                                </Map>
                            </APIProvider>
                            </div>
                        </div>
                    </div>
                    <div className="btnContainer">
                        <Link to={`/2`} className="btn btn-outline">
                            To step 2
                        </Link>
                    </div>
                </>
            )}
        </div>
    )
}

export default RentalStep1


const PoiMarkers = (props: { pois: Poi[] }) => {
    const map = useMap();
    const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
    const [activeMarkerKey, setActiveMarkerKey] = useState<string | null>(null);
    const clusterer = useRef<MarkerClusterer | null>(null);

    // Initialize MarkerClusterer, if the map has changed
    useEffect(() => {
        if (!map) return;
        if (!clusterer.current) {
            clusterer.current = new MarkerClusterer({ map });
        }
    }, [map]);

    // Update markers, if the markers array has changed
    useEffect(() => {
        clusterer.current?.clearMarkers();
        clusterer.current?.addMarkers(Object.values(markers));
    }, [markers]);

    const setMarkerRef = (marker: Marker | null, key: string) => {
        if (marker && markers[key]) return;
        if (!marker && !markers[key]) return;

        setMarkers(prev => {
            if (marker) {
                return { ...prev, [key]: marker };
            } else {
                const newMarkers = { ...prev };
                delete newMarkers[key];
                return newMarkers;
            }
        });
    };

    const handleClick = useCallback((ev: google.maps.MapMouseEvent, key: string) => {
        if (!map || !ev.latLng) return;
        map.panTo(ev.latLng);

        // Toggle the active marker's key
        setActiveMarkerKey(prevKey => (prevKey === key ? null : key));
    }, [map]);

    const handleClose = useCallback(() => setActiveMarkerKey(null), []);

    return (
        <>
            {props.pois.map((poi: Poi) => (
                <AdvancedMarker
                    key={poi.key}
                    position={poi.location}
                    ref={marker => setMarkerRef(marker, poi.key)}
                    clickable={true}
                    onClick={(ev) => handleClick(ev, poi.key)}
                >
                    <Pin background={'#f07d00'} glyphColor={'#fff'} borderColor={'#fff'} />

                    {activeMarkerKey === poi.key && (
                        <InfoWindow
                            anchor={markers[poi.key]} // Use the stored marker reference
                            onClose={handleClose}
                        >
                            <div>
                                <p className="m-0 text-base">{poi.company}</p>
                                <p className="text-sm">{poi.street}, {poi.postalCode} {poi.city}</p>
                            </div>
                        </InfoWindow>
                    )}
                </AdvancedMarker>
            ))}
        </>
    );
};