import {useLocation, useParams} from "react-router-dom";

const Footer: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Specify that id is a string

    const routerLocation: Location = useLocation();
    const currentPath = routerLocation.pathname; console.log('currentPath', currentPath, id);

    return (
        <footer className="px-3 py-1 border border-neutral-200/60 rounded text-sm">
            <div>path: {currentPath}, current machine: {id || '?'}</div>
        </footer>
    )
}

export default Footer