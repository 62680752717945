import {Link, useLocation, useParams} from "react-router-dom";
import {ROUTES} from "./MachinesRoot";

const Breadcrumbs: React.FC = () => {
    const {id} = useParams<{ id: string }>(); // Specify that id is a string

    const routerLocation: Location = useLocation();
    const currentPath = routerLocation.pathname;
    const isRootPath = currentPath === '/';

    const ArrowIcon: React.FC = () => (
        <svg className="w-5 h-5 text-neutral-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g fill="none" stroke="none">
                <path d="M10 8.013l4 4-4 4" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>
    )

    return (
        <nav className="p-3 pt-1.5 pb-0 border border-neutral-200/60 rounded" aria-label="breadcrumb">
            <ol className="breadcrumbs | inline-flex items-center space-x-1 text-sm [&_.active-breadcrumb]:text-primary leading-none">
                <li className="leading-none">
                    {isRootPath ? (
                        <span key="home" className="focus:outline-none active-breadcrumb">Machines</span>
                    ) : (
                        <Link key="home" className="focus:outline-none no-underline" to={ROUTES.LIST}>Machines</Link>
                    )}
                </li>
                {!isRootPath && (
                    <>
                        <ArrowIcon/>
                        <li>
                            <span key="detail" className="active-breadcrumb focus:outline-none">
                                Machine detail {id || '?'}
                            </span>
                        </li>
                    </>
                )}
            </ol>
        </nav>
    )
}

export default Breadcrumbs