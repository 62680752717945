import {useState} from "react";
import {useNavigate} from 'react-router-dom'
import {useLocalStorage} from "./useLocalStorage";
import {useFetch} from "../context/FetchContext";
import {User} from "../models/global.model";

type AuthStore = {
    user: User | null
    token: string | null
    username: string
    setUsername: React.Dispatch<React.SetStateAction<string>>
    password: string
    setPassword: React.Dispatch<React.SetStateAction<string>>
    loading: boolean
    error: string | null
    login: (e: React.FormEvent) => Promise<boolean>
    register: (e: React.FormEvent) => Promise<void>
    logout: () => void
}

export const useAuth = (): AuthStore => {
    const fetchData = useFetch()
    const navigate = useNavigate()

    const [user, setUser] = useLocalStorage('user', null)
    const [token, setToken] = useLocalStorage('userToken', null)

    const [username, setUsername] = useState('demoWebsite')
    const [password, setPassword] = useState('demoWebsite')
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    const login = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoading(true)
        setError(null)
        await new Promise((resolve) => setTimeout(() => resolve(true), 1000)) // Simulate delay for demonstration purposes

        try {
            const user: User = await fetchData<User>(`/auth/login/websiteUser`, 'POST', {
                userName: username,
                userPassword: password
            }, {Authorization: undefined});
            if (user) {
                setUser(user);
                setToken(user.token);
                navigate('/', {replace: true});
            }
        } catch (err) {
            setError(err?.message || 'An error occurred while logging in.');
        } finally {
            setLoading(false)
        }
    }

    const register = async (formData) => {
        setLoading(true)
        setError(null)
        await new Promise((resolve) => setTimeout(() => resolve(true), 1000)) // Simulate delay for demonstration purposes
/*{
                userName: username,
                userPassword: password,
                roles: ["website", "user"],
                descr: "Demo website",
                // "hostname": "localhost:8006",
                // "IPAddress": "10.0.0.1"
            }*/
        try {
            const response = await fetchData<User>(`/auth/websiteUser`, 'POST', formData)
        } catch (err) {
            setError(err?.message || 'An error occurred while registering.');
        } finally {
            setLoading(false)
        }
    }

    const logout = () => {
        setUser(null);
        setToken(null);
        navigate('/login', {replace: true});
    }

    return {
        user,
        token,
        username,
        setUsername,
        password,
        setPassword,
        loading,
        error,
        login,
        register,
        logout,
    }
}