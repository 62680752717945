import { getAuthToken } from '../utils/auth'

interface ApiResponse<T> {
  success: boolean
  message?: string
  data: T
}

const { VITE_API_URL: HOSTNAME = 'https://uat.api.tuinmachine-verhuur.be' } = import.meta.env

/**
 * Data Fetching Service - Handles network requests, response parsing, and error handling.
 *
 * Define a service file where you create a function that performs the fetch.
 * You can add any custom logic you like (e.g., error handling, response parsing) here.
 */
async function apiRequest<T, U = never>(
  path: string,
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  body?: U,
  extraHeaders?: Record<string, string | undefined>
): Promise<T> {
  const token = getAuthToken()

  const defaultHeaders: Record<string, string | undefined> = {
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : undefined,
    Accept: 'application/json',
  }
  const headers = { ...defaultHeaders, ...extraHeaders }

  const controller = new AbortController()
  setTimeout(() => controller.abort(), 2000)

  // Create the request options
  const options = {
    signal: controller.signal, // Abort the request if the controller's signal is aborted
    method,
    headers,
    body: method !== 'GET' && body ? JSON.stringify(body) : undefined, //body ? JSON.stringify(body) : undefined, // Only include the body for non-GET methods
  } as RequestInit

  try {
    console.log('apiRequest', `${HOSTNAME}${path}`, options)
    const response = await fetch(`${HOSTNAME}${path}`, options)

    const result: ApiResponse<T> = await response.json()

    if (!response.ok || !result?.success) {
      const statusText = response?.statusText || result?.message || 'No extra information available.'
      throw new Error(statusText) //Error(`HTTP error, status ${response.status} - ${statusText}`)
    }

    if (!result.success) {
      throw new Error(`${result?.message || 'An error occurred.'}`)
    }

    return result.data as T
  } catch (error) {
    console.error(`[fetchService] ${method} ${path} failed:`, error)
    throw error
  }
}

export default apiRequest
