import {FormLogin, FormRegister} from "../forms";

const AccountLogin = () => {
    return (
        <div className="grid-default grid-cols-2">
            <div>
                <h2 className="text-3xl">Ik ben reeds klant?</h2>
                <p>Meld je hier aan om een reservatie te plaatsen.</p>
                <div className="max-w-lg p-6 border border-primary rounded">
                    <FormLogin/>
                </div>
            </div>
            <div>
                <h2 className="text-3xl">Nieuw bij Eliet?</h2>
                <p>De inschrijving is snel en eenvoudig en biedt heel wat voordelen.</p>
                <div className="p-6 border border-primary rounded">
                    <FormRegister/>
                </div>
            </div>
        </div>
    );
};

export default AccountLogin;
