import React from "react";
import {Link} from "react-router-dom";
import {goToRentalPage} from "./MachinesRoot";

interface MachineItemProps {
    url: string;
    id: number;
    title: string;
    price: number;
    short: string;
    image: {
        src: string;
        alt?: string;
    } | null;  // null/undefined if no image
}

const MachineItem: React.FC<MachineItemProps> = ({
    url, id, title, price, short, image
}) => {
    return (
        <div className="max-w-[450px] grid gap-5">
            <div className="relative">
                <Link to={url} className="" aria-label={`More info about ${title}`}>
                <picture>
                    <img src={image?.src ?? 'https://placecats.com/450/670'} alt={image?.alt ?? 'Machine'} className="w-full aspect-[450/670] h-auto rounded opacity-80"/>
                </picture>
                </Link>
                <div className="p-5 absolute top-0 left-0 right-0 z-10 flex justify-between items-center">
                    <h2 className="m-0 px-4 py-0.5 text-[25px] font-bold text-nowrap leading-none text-lg bg-dark text-primary rounded">{title}</h2>
                    <span className="m-0 text-2xl text-white">{price}/day</span>
                </div>
                <div className="p-5 absolute bottom-0 left-0 right-0 z-10 text-white" dangerouslySetInnerHTML={{
                    __html: short
                }}/>
            </div>
            <div className="flex gap-8">
                <button className="btn" onClick={() => goToRentalPage(id)}>Rental</button>
                <Link to={url} className="btn btn-outline" aria-label={`More info about ${title}`}>
                    More info
                </Link>
            </div>
        </div>
    )
}

export default MachineItem